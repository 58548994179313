:root {
  --blue: #3C65F4;
}

//.App {
//    padding: 0 10px;
//}

.Preloader {
  margin-top: 10vh;
  &_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100px;
    &_logo {
      width: 140px;
      height: 137px;
      // animation: preloader-animation 2s infinite;
    }
    &_text {
      font-family: 'Roboto 700', sans-serif;;
      font-size: 40px;
      // padding-left: 30px;
      // color: gray;
      margin-top: 50px;
    }
  }
}
